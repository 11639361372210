export const environment = {
	production: false,
	uploadEndPoint: '/api/upload',
	taxLevel: 'taxlevel',
	baseUrlAwsS3: 'https://staging.api.nexgentaxes.com',
	apiBase: 'https://staging.api.nexgentaxes.com/',
	apiProdBase: 'https://production.api.nexgentaxes.com/',
	apiBaseKey: 'f488c628a5fdf8b17861bb8d4947a5b3eb00d2f8',
	conversationApi: 'https://communications-service-staging.herokuapp.com/',
	stripe: {
		publishable:
			'pk_test_51KME7XBD9tkiJntN22IRp9quDR3BCh1PuAt8Aga3eKk5Cc3Yg1elsRuKxPI7gcuQZSqBhr4E7ysyYAH4zM61VpAu00KfCycdjP',
		secret: 'sk_test_51KME7XBD9tkiJntNTv5KWq6R1vXmeCbgRAIiwrtCN5Vqa9wmO8VDwbYMr3QKvAKqZ0xJHh7UwcDYRXMU7q4sAixk00Xvr6zsLq',
	},
	userRole: 'user',
	docusignUrl: 'https://app.nexgentaxes.com/docusign',
	mongoid: 'nexgen-dev-jbooj',
	firebaseConfig: {
		apiKey: 'AIzaSyD0oVWENCpav5miZOAi7x4cJiBHupJoUbU',
		authDomain: 'nexgen-taxes-staging-5abfb.firebaseapp.com',
		projectId: 'nexgen-taxes-staging-5abfb',
		storageBucket: 'nexgen-taxes-staging-5abfb.appspot.com',
		messagingSenderId: '656143052757',
		appId: '1:656143052757:web:b066e029e588de85d3175c',
	},

	appIsFor: 'nexGenTaxes',
	// appIsFor: 'forMyTax',
	// appIsFor: 'rushTimeTax',

	appTitle: 'NexGen Taxes',
	// appTitle: 'For My Tax',
	// appTitle: 'Rush Time Tax',

	termsOfService: 'https://nexgentaxes.com/vendor-terms-of-use/',
	privacyPolicy: 'https://nexgentaxes.com/privacy/',
	supportEmail: 'support@nexgentaxes.com',
	supportPhone: '1 307-387-1025',
	supportId: 'LmSysmVSmAM8TqyHFWOsxfuynOD2',

	appLink: 'https://app.qa.nexgentaxes.com/',
};
