import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	NavController,
	ToastController,
	AlertController,
} from '@ionic/angular';

import * as geolib from 'geolib';

declare let google;

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	constructor(
		private toastController: ToastController,
		private alertController: AlertController,
		private navCtrl: NavController,
		private router: Router
	) {}

	async presentToastWithOptions(message: string) {
		const toast = await this.toastController.create({
			header: message,
			position: 'middle',
			duration: 1500,
		});
		toast.present();
	}

	navigateRoot(url, options?) {
		this.navCtrl.navigateRoot(url, options);
	}
	navigateByUrl(url, options?) {
		this.router.navigateByUrl(url);
	}

	navigateForward(url, options?) {
		this.navCtrl.navigateForward(url, options);
	}
	navigateBack(url, options?) {
		this.navCtrl.navigateBack(url, options);
	}

	navigate(url: string) {
		this.router.navigate([url]);
	}

	reverseAddressSearch(address: string) {
		const geocoder = new google.maps.Geocoder();
		return new Promise<any>((resolve, reject) => {
			geocoder.geocode({ address }, function (results, status) {
				if (status === google.maps.GeocoderStatus.OK) {
					if (results) {
						results = results[0];
						if (results && !results.err) {
							const rsltAdrComponent = results.address_components;
							let number;
							let street;
							let city;
							let state;
							let zipcode;

							for (let i = 0; i < rsltAdrComponent.length; i++) {
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'street_number'
									)
								) {
									number = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes('route')
								) {
									street = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'locality'
									)
								) {
									city = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'postal_code'
									)
								) {
									zipcode = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'administrative_area_level_1'
									)
								) {
									state = rsltAdrComponent[i].short_name;
								}
							}
							resolve({ number, street, city, state, zipcode });
						}
					}
				} else {
					resolve('Error reversing location results');
				}
			});
		});
	}

	async createToast(message, duration = 3000): Promise<HTMLIonToastElement> {
		const toast = await this.toastController.create({
			message,
			position: 'middle',
			duration,
			buttons: [
				{
					text: 'Ok',
					role: 'cancel',
					handler: () => {},
				},
			],
		});
		return toast;
	}

	async presentAlert(header: string, subHeader: string, message: string) {
		const alert = await this.alertController.create({
			header,
			subHeader,
			message,
			buttons: ['OK'],
		});

		await alert.present();
	}

	async createAlert(
		header,
		backdropDismiss,
		message,
		buttonOptions1,
		buttonOptions2?
	): Promise<HTMLIonAlertElement> {
		const alert = await this.alertController.create({
			header,
			backdropDismiss,
			message,
			buttons: !buttonOptions2
				? [buttonOptions1]
				: [buttonOptions1, buttonOptions2],
		});
		return alert;
	}

	async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
		const service = new google.maps.places.AutocompleteService();
		let pred;
		await new Promise((resolve, reject) => {
			service.getPlacePredictions(
				{
					input: searchText,
					componentRestrictions: { country: country || 'US' },
				},
				(predictions) => {
					pred = predictions;
					resolve(true);
				}
			);
		});
		return pred;
	}

	formatPhone($event) {
		let phone = $event.target.value.replace(/[^\d]/g, '');
		let len = phone.length;
		phone = len > 10 ? phone.substr(0, 10) : phone;
		phone =
			len > 3 ? phone.substr(0, 3) + '-' + phone.substr(3, len) : phone;
		phone =
			len > 6
				? phone.substr(0, 7) + '-' + phone.substr(7, len + 1)
				: phone;

		return phone;
	}

	async presentToast(message: string) {
		const toast = await this.toastController.create({
			header: message,
			position: 'middle',
			duration: 3500,
		});
		toast.present();
	}

	async createErrorAlert(title, message) {
		if (title && message) {
			const alert = await this.createAlert(title, false, message, {
				text: 'Ok',
				handler: async () => {},
			});
			await alert.present();
		}
	}

	/**
	 *
	 * @param address
	 * @returns
	 */
	convertAddressToLatLng(address: string) {
		const geocoder = new google.maps.Geocoder();
		return new Promise<any>((resolve, reject) => {
			geocoder.geocode({ address }, function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results &&
					!results.err
				) {
					const latitude = results[0].geometry.location.lat();
					const longitude = results[0].geometry.location.lng();
					resolve({
						lat: latitude,
						lng: longitude,
						state:
							results[0].address_components[3] &&
							results[0].address_components[3].short_name
								? results[0].address_components[3].short_name
								: '',
					});
				} else {
					resolve({});
				}
			});
		});
	}

	async getDistanceBtw2points(a: any, b: any) {
		// a, b = { latitude: .., longitude: ... }
		const distance = await geolib.getPreciseDistance(a, b);
		return distance;
	}

	async convertToMiles(d) {
		console.log('distance ----->', d);
		return (0.621371 * d) / 1000;

		//const dist = geolib.convertDistance(d, 'mi')
		//return Math.round(10 * dist) / 10
	}

	/**
	 * calcul distance
	 * @param latLng
	 * @param zipCode
	 * @returns
	 */
	calcDistance(latLng: any, zipCode: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.convertAddressToLatLng(zipCode).then(
					async (coords: any) => {
						if (coords && coords.lat && coords.lng) {
							const a = {
								latitude: coords.lat,
								longitude: coords.lng,
							};

							const distance = await this.getDistanceBtw2points(
								a,
								latLng
							);

							const d = Number(
								((0.621371 * distance) / 1000).toFixed(2)
							);
							const nearBy = d < 300 ? true : false;
							resolve({ d, nearBy });
						} else resolve({});
					}
				);
			} catch (e) {
				console.log('calcDistance err ->', e);
				resolve({});
			}
		});
	}
}
